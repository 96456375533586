/* global rp$, window, grecaptcha */
var THE_SCRIPT = function () {

	const $onReady = require('public/js/lib/on-ready');

	/********************************************
	 * Load Javascript for the Banner Vpicker Module
	 * -----------------------------------------
	 * @section banner_vpicker_js
	 ********************************************/
	(function ContactFormModule() {
		'use strict';
		$onReady({
			rp$, window,
			label: 'contact_form',
			fn: function () {
				var $contactForms, $contactFormEle, $successEle, $errorsEle;

				$contactForms = rp$('.contact-form-module');

				rp$.each($contactForms, function ContactFormModule_Iterator(idx, contactFormEle) {
					$contactFormEle = rp$(contactFormEle);
					$successEle = $contactFormEle.find('.success-overlay');
					$errorsEle = $contactFormEle.find('.contact-form-errors');

					function contactFormWorking() {
						$contactFormEle.find('.contact-form-submit').addClass('disabled');
					}
					function contactFormReady() {
						$contactFormEle.find('.contact-form-submit').removeClass('disabled');
					}

					function contactFormHandleErrors(errors) {
						var errorsEleOffset, windowHeight, windowTop;

						/* Fill errors
						.*-----------------------------------------*/
						$errorsEle.show().empty();
						for(var i = 0, j = errors.length; i < j; i++) {
							var errMsg = errors[i];
							$errorsEle.append('<p>'+ errMsg +'</p>');
						}

						// Recaptcha submission is invalidated. Reload it.
						if(typeof grecaptcha === 'object') {
							grecaptcha.reset();
						}

						/* If errors are out of view (above or below window pane), scroll to it
						.*-----------------------------------------*/
						errorsEleOffset = $errorsEle.offset();
						windowHeight = rp$(window).height();
						windowTop = rp$(window).scrollTop();
						if((errorsEleOffset.top < windowTop) || (errorsEleOffset.top > (windowTop + windowHeight))) {
							rp$('html, body').animate({
								scrollTop: $errorsEle.offset().top - 40
							});
						}

						focusFirstEmpty($contactFormEle);
					}

					function contactFormHandleSuccess() {
						$successEle.fadeIn();
						$errorsEle.addClass('hidden').empty();
					}

					function focusFirstEmpty($form) {
						var fields = ['name','email','phone-number','reason','brand','order-id','vin','subject','message'];
						for (var i = 0; i < fields.length; i++) {
							var field = $form.find('.msg-'+ fields[i]);
							if(!field.val()) {
								field.focus();
								break;
							}
						}
					}
					focusFirstEmpty($contactFormEle);

					function contactFormSubmit() {
						var form = {
							name: null,
							email: null,
							phone: null,
							reason: null,
							brand: null,
							orderNum: null,
							vin: null,
							subject: null,
							message: null,
							captchaResponse: null
						};

						/* Pull in data from the form
						.*-----------------------------------------*/
						form.name = $contactFormEle.find('.msg-name').val();
						form.email = $contactFormEle.find('.msg-email').val();
						form.phone = $contactFormEle.find('.msg-phone-number').val();
						form.reason = $contactFormEle.find('.msg-reason').val();
						form.brand = $contactFormEle.find('.msg-brand').val();
						form.orderNum = $contactFormEle.find('.msg-order-id').val();
						form.vin = $contactFormEle.find('.msg-vin').val();
						form.subject = $contactFormEle.find('.msg-subject').val();
						form.message = $contactFormEle.find('.msg-message').val();
						// Recapchta fields
						form.captchaResponse = null;
						if(typeof grecaptcha === 'object') {
							form.captchaResponse = grecaptcha.getResponse();
						}

						/* Perform Submission
						.*-----------------------------------------*/
						contactFormWorking();
						window.rp_app.request({
							method: 'POST',
							url: '/ajax/contact-us',
							dataType: 'json',
							data: form,
							success: function contactFormSubmit_Success(/*response*/) {
								contactFormHandleSuccess();
							},
							statusCode: {
								400: function(jqxhr) {
									contactFormReady();
									var error_details;
									if(jqxhr.hasOwnProperty('responseText')) {
										try {
											error_details = JSON.parse(jqxhr.responseText);
										} catch(e) {
											error_details = {
												errors: ['There was a problem with your form submission. Please try again.']
											};
										}
									}
									contactFormHandleErrors(error_details.errors);
								}
							},
							error: function contactFormSubmit_Error(jqxhr/*, status error*/) {
								if(typeof this.statusCode[jqxhr.status] === 'function') { return; } // StatusCode handled
								contactFormReady();
								contactFormHandleErrors(['There was a problem with your request. Please wait a few moments and try again.']);
							}
						});
					}

					// attach select2 UX to select inputs
					$contactFormEle.find('select.msg-reason').select2();
					$contactFormEle.find('select.msg-brand').select2();
					// attach form submit handler
					$contactFormEle.on('click', '.contact-form-submit', function (evt) {
						evt.preventDefault();
						if(rp$(this).hasClass('disabled')) {
							return;
						}

						contactFormSubmit();
					});
				});
			},
		});

	})();
};

try {
	// Because __CURRENT_SCRIPT__ is injected by plugin Client, we need this here to try and pass it up to the plugin code
	__CURRENT_SCRIPT__ = THE_SCRIPT;
} catch (e) {
	THE_SCRIPT();
}
